<template>
  <v-data-table
    :headers="headers"
    :items="acessos"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
    hide-default-footer
    disable-pagination
    no-results-text="Nenhum registro correspondente encontrado"
    :loading="loading"
    :hide-default-header="loading"
  />
</template>

<script>
export default {
  name: 'EmpresasLiberadasUsuarioDataTable',

  props: {
    acessos: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    sortBy: 'empresa',
    sortDesc: false,
    headers: [
      { text: 'Empresa', value: 'empresa' },
      { text: 'Perfil', value: 'perfil' }
    ]
  })
};
</script>

<style></style>
